import Header from "../components/Header";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import './Gallery.css';

function Gallery() {
    return (
        <div className="Gallery">
            <Header />
            <img alt="babaápolás2" src={require("../static/img/babaapolas2_big.webp")} />
            <img alt="babaápolás4" src={require("../static/img/babaapolas4_big.webp")} />
            <img alt="babaápolás6" src={require("../static/img/babaapolas6_big.webp")} />
            <img alt="szoptatás táplálás" src={require("../static/img/szoptatas_taplalas1_big.webp")} />
            <img alt="babaápolás1" src={require("../static/img/babaapolas1_big.webp")} />
            <img alt="szoptatás táplálás" src={require("../static/img/szoptatas_taplalas5_big.webp")} />
            <img alt="szoptatás táplálás" src={require("../static/img/szoptatas_taplalas6_big.webp")} />
            <img alt="szoptatás táplálás" src={require("../static/img/szoptatas_taplalas3_big.webp")} />
        
            <img alt="babamasszázs" src={require("../static/img/babamasszazs2_big.webp")} />
            <img alt="elsősegély" src={require("../static/img/elsosegely1_big.webp")} />
            <img alt="elsősegély" src={require("../static/img/elsosegely3_big.webp")} />
            <img alt="elsősegély" src={require("../static/img/elsosegely4_big.webp")} />
            <img alt="elsősegély" src={require("../static/img/elsosegely5_big.webp")} />
            <img alt="elsősegély" src={require("../static/img/elsosegely6_big.webp")} />
            <img alt="kismama masszázs" src={require("../static/img/kismama_masszazs2_big.webp")} />
            
            <img alt="kismama masszázs" src={require("../static/img/kismama_masszazs4_big.webp")} />
            <img alt="kismama masszázs" src={require("../static/img/kismama_masszazs3_big.webp")} />
            <img alt="kismama masszázs" src={require("../static/img/kismama_masszazs1_big.webp")} />
            <img alt="kismama masszázs" src={require("../static/img/kismama_masszazs5_big.webp")} />
            

            <CTA />
            <Footer />
        </div>
    );
}

export default Gallery;