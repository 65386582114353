import Header from "../components/Header";
import Footer from "../components/Footer";
import './Landing.css';

function Landing() {
    return (
        <div className="Landing">
            <Header />
            <img class='fokep' alt="főkép" src={require("../static/img/babaapolas5_big.webp")} />
            <h1 class="font-bold">"Minden, ami a babádról és rólad szól, tabuk nèlkül."</h1>
            <p>Lakatos Anita vagyok Csecsemő- és Gyermekápoló, dúla. Emellett okleveles elsősegély oktató, babamasszázs oktató, önkéntes szoptatási segítő és kismamamasszőr, a kéSzülő alapítója.</p>
            <Footer />
        </div>
    );
}

export default Landing;