import Header from "../components/Header";
import Footer from "../components/Footer";

function Contact() {
    return (
        <div className="Contact">
            <Header />
            <h1>Elérhetőségek</h1>
            <div className="font-bold ">
                <h1>Telefon</h1>
                <p>+36 30/200-4646</p>
            </div>
            <div className="contact-div">
                <h1>Instagram</h1>
                <p>https://www.instagram.com/keszulo.hu</p>
            </div>
            <div className="contact-div">
                <h1>E-mail</h1>
                <p>anita.keszulo@gmail.com</p>
            </div>
            <div className="contact-div">
                <h1>tiktok</h1>
                <p>https://www.tiktok.com/@lakatosanitaa98</p>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;