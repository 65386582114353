import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useEffect, useState } from 'react';
import './Admin.css';
import { listServices, addService, updateService } from '../utils/firestore';

function Admin() {
    const [isChanging, setIsChanging] = useState(false);
    const [services, setServices] = useState([]);
    const [serviceChanges, setServiceChanges] = useState({});

    async function fetchServicesFromFirestore() {
        const services = await listServices();
        setServices(services);
    }

    useEffect(() => {
        fetchServicesFromFirestore();
    }, []);


    const handleInputChange = (e, serviceId) => {
        const { name, value } = e.target;
        setServiceChanges((prevChanges) => ({
            ...prevChanges,
            [serviceId]: {
                ...prevChanges[serviceId],
                [name]: value,
            },
        }));
        setIsChanging(true);
    };

    const handleSaveChanges = () => {
        const updatedServices = services.map((service) => {
            const changes = serviceChanges[service.id] || {}; // Get changes for this service
            if (Object.keys(changes).length !== 0) {
                updateService(service);
            }
            return {
                ...service,
                ...changes, // Apply changes
            };
        });

        setServices(updatedServices);
        setIsChanging(false);
        setServiceChanges({}); // Clear the changes
    };

    return (
        <div className="Admin">
            <Header />
            <h1>Szolgáltatások szerkesztése</h1>
            {services && services.map((service) => {
                return (
                    <div key={'service' + service.id} className="service-info" >
                        <h2>{service.name}</h2>
                        <div >
                            <label htmlFor="nearestDate">Legközelebbi időpont</label>
                            <input type="date" id="nearestDate" name="nearestDate" onChange={(e) => handleInputChange(e, service.id)} defaultValue={new Date(service.nearestDate.seconds * 1000).toISOString().split('T')[0]} />
                        </div>
                        <div>
                            <label htmlFor="price">Ár</label>
                            <input type="number" id="price" name="price" onChange={(e) => handleInputChange(e, service.id)} defaultValue={service.price} />
                        </div>
                        <div>
                            <label htmlFor="contains">Tartalma</label>
                            <input type="text" id="contains" name="contains" onChange={(e) => handleInputChange(e, service.id)} defaultValue={service.contains} />
                        </div>
                        <div>
                            <label htmlFor="remaining">Hátralévő alkalom</label>
                            <input type="number" id="remaining" name="remaining" onChange={(e) => handleInputChange(e, service.id)} defaultValue={service.remaining} />
                        </div>
                        <h1 hidden={!isChanging} onClick={() => handleSaveChanges()}>💾</h1>
                    </div>
                )
            })}
            <h1 onClickCapture={() => addService()}>➕</h1>
            <Footer />
        </div>
    );
}

export default Admin;