
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Footer.css';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'

function Footer() {
  return (
    <div className="Footer">
      <div className="centered-label">
        <FontAwesomeIcon icon={faTiktok} size="xl" />
        
        <h2><a href='https://www.tiktok.com/@lakatosanitaa98'>@lakatosanitaa98  a tiktokon</a></h2>
      </div>

      <div className="centered-label">
        <FontAwesomeIcon className='footerIcon' size="xl" icon={faInstagram} />
        <h2><a href='https://www.instagram.com/keszulo'>keszulo</a></h2>
      </div>


      <div className="centered-label">
        <FontAwesomeIcon className='footerIcon' size="xl" icon={faAt} />
        <h2><a href="mailto:anita.keszulo@gmail.com" target="_top" rel="noreferrer"
        >anita.keszulo@gmail.com</a>
        </h2>        

      </div>

    </div>
  );
}

export default Footer;
