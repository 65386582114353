import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';
import './Prices.css';
import CTA from "../components/CTA";

function Pricing() {

    return (
        <div className="Pricing">
            <Header />
            <h2>Szolgáltatásaim</h2>
            <div className="service-menu">
            <h2>TANFOLYAMOK:</h2>
            <ul>
                <li><h3><span >Babaápolási Tanfolyam</span></h3>  <h2><span>18.900 Ft/pár</span></h2> </li>
                <li><h3><span >Szoptatási-táplálási tanfolyam</span></h3>  <h2><span>18.900 Ft/pár</span></h2></li>
                <li><h3><span >Csecsemő elsősegély tanfolyam</span></h3>  <h2><span>18.900 Ft/pár</span></h2></li>
                <li> <h3><span >Babamasszázs tanfolyam</span></h3> <h2><span>18.900 Ft/pár</span></h2></li>
            </ul>
            </div>
            <CTA />
            <div className="service-menu">
            <h2>EGYÉB SZOLGÁLTATÁSOK</h2>
            <ul>
                <li><span >Kismama masszázs</span> <h2><span>9.500 Ft/óra</span></h2></li>
                <li><span >Gyermekágyas látogatás</span> <h2><span>15.000 Ft/2 óra</span></h2></li>
                <li><span >"Anya énidő" (masszázs)</span> <h2><span>9.500 Ft/óra</span></h2></li>
                
                <li><span>Szülés előtti konzultáció: egyéni árajánlat a konzultációk számától függően</span></li>
            </ul>
            </div>
            <Footer />
        </div>
    );
}

export default Pricing;