// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { setDoc, doc, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAJd0H9vmXHSFZOPAQ0LydA6O147MDxcps",
  authDomain: "keszulo.firebaseapp.com",
  projectId: "keszulo",
  storageBucket: "keszulo.appspot.com",
  messagingSenderId: "197685098402",
  appId: "1:197685098402:web:5aefe9addbcd21c04ce4fb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const services = [];

async function listServices() {
  const servicesCol = collection(db, 'services');
  const servicesSnapshot = await getDocs(servicesCol);
  const servicesList = servicesSnapshot.docs.map(doc => doc.data());
  const servicesOrdered = servicesList.sort((a, b) => {
    return a.order - b.order;
  });
  return servicesOrdered;
}

async function addService() {
  try {
    const newService = {
      name: "",
      nearestDate: new Date(),
      price: 0,
      contains: "",
      remaining: 0,
    };

    // Add the new service to Firestore
    const docRef = collection(db, 'services');
    await addDoc(docRef, newService);

    // Update the services state with the new service data
    services([...services, { id: docRef.id, ...newService }]);
    return services;
  } catch (error) {
    console.error('Error adding service to Firestore:', error);
  }
}

async function updateService(service) {
  try {
    await setDoc(doc(db, "services", service.id), service);

    const updatedServices = services.map((s) => {
      if (s.id === service.id) {
        return service; 
      } else {
        return s; 
      }
    });

    return updatedServices;
  } catch (error) {
    console.error('Error updating services in Firestore:', error);
  }
}

export { listServices, addService, updateService };