import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';
import './Services.css';
import CTA from "../components/CTA";

function Services() {


    return (
        <div className="Services">
            <Header />
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/babaapolas5_big.webp")} />
                <h2>Babaápolás tanfolyam</h2>
                <p>Várandósként felmerült benned is, vajon milyen kihívásokkal kell szembenézned a szülés után kisbabád ellátásával kapcsolatban? Megnyugtatlak, teljesen normális, ha bizonytalan vagy abban, hogy mi fog történni kisbabád érkezését követően, hogyan kell majd helyesen tartanod, pelenkáznod, fürdetned, büfiztetned, ápolnod, vagy éppen milyen számos okai lehetnek annak, ha újszülött babád sír. Amellett, hogy ezeken kívül még rengeteg témát értünk, mint például: köldökcsonkápolás,körömvágás,stbs., megannyi gyakorlati tudást is elsajátíthatsz a tanfolyamon, melyre egyedül, pároddal, vagy hozzátartozóddal érkezhetsz. Minden ápolási tevékenység, a szülőket bevonva oktatóbabákon történik, így nem csak elméleti, hanem gyakorlati tudással is gazdagodnak a résztvevők.</p>
                <CTA />
            </div>
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/elsosegely2_big.webp")} />
                <h2>Csecsemő/gyermekelsősegély tanfolyam</h2>
                <p>Szülőként az egyik legnagyobb magabiztosságot adja, ha tisztában vagyunk, mit kell tenni baj esetén. Tanfolyamom során mind elméletben és gyakorlatban is elsajátíthatják a szülők az esetlegesen előforduló eseteket, mint például: mit kell tenni fulladás, mérgezés, sebellátás esetén. Megtanuljuk az újraélesztést, idegen test eltávolítását, a segélyhívás menetét és még nagyon sok ellátási formát, amire szükség lehet. Emellett átbeszéljük a gyakori gyermekbetegségeket és a vele járó tennivalókat.</p>
                <CTA />
            </div>
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/szoptatas_taplalas2_big.webp")} />
                <h2>Szoptatásra,táplálásra felkészítő tanfolyam</h2>
                <p>A szoptatás sokkal több, mint egy táplálási forma. Mindamellett, hogy gyermekünk táplálását biztosítjuk, emellett erősítjük, mélyítjük vele kapcsolatunkat. De, hogy ez a lehető legharmónikusabban és legmagabiztosabban működjön közted és babád közt, megtanuljuk és gyakoroljuk a helyes mellre helyezési pozitúrákat, a szopizás mechanizmusát, mikor/mennyit szoptassunk, fejési technikákat és még nagyon sok hasznos elméleti és gyakorlati tudással térhetsz haza. Nincs tabu téma, bármit megbeszélhetünk! Illetve bevezetlek a hozzátáplálás világába is.</p>
                <CTA />
            </div>
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/babamasszazs3_big.webp")} />
                <h2>Babamasszázs tanfolyam</h2>
                <p>Érintés. Szülő-gyermek közötti kapcsolat mélyítése. Alvás minőségének javítása, hasfájás enyhítése. És még sorolhatnám a babamasszázs megannyi jótékony hatásait. Ha szeretnéd megadni ezt a plusz töltekezést babádnak és neked, keress bátran! Nem csak anyukáknak, hanem apukáknak, nagyszülőknek, hozzátartozóknak is ajánlom. Tanuljuk meg együtt a gyakorlati órákon a babamasszázs összes mozdulatát.</p>
                <CTA />
            </div>
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/kismama_masszazs2_big.webp")} />
                <h2>Kismamamasszázs</h2>
                <p>Itt minden rólad szól. Biztonságos, lágy, pihentető énidő, mely jótékony hatását nem csak a kismama, hanem a pocaklakója is érezni fog. Csökken a stressz, javul az alvás, segít az izom és izületi fájdalmak enyhítésében. </p>
                <CTA />
            </div>
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/konzultacio_big.webp")} />
                <h2>Szülés előtti konzultációk</h2>
                <p>A várandósság az egyik legcsodálatosabb állapot, ami egy nő életében történhet. Ám rengeteg testi és lelki változásokon megy keresztül a  kismama ,sok kérdés felmerülhet benne. Ha szeretnél választ kapni kérdéseidre, különböző témakörökkel tisztában lenni, mint pl. a vajúdási technikák,a szülés alatti fájdalomcsillapítási formák,a hüvelyi szülés,császármetszés menete, vagy,hogy a párod hogyan segíthet a vajúdás alatt,a szülés körül és azt követően, és még megannyi témát érintve , keress bizalommal. Az első konzultáción meg ismerkedünk és együtt kialakítjuk, mire van szükséged, milyen témaköröket érintsünk,hány alkalomból álljon a felkészülés,melyek felkészítenek a nagy napra. Nagy hangsúlyt fektetek mindig az édesapákra , a hozzátartozókra is. Természetesen érkezhetsz ezekre az alkalmakra egyedül, pároddal,vagy bárkivel, aki támaszt nyújt számodra.</p>
                <CTA />
            </div>
            <div>
            <img alt="logo" className='services-image' src={require("../static/img/babaagyas_kiseres_big.webp")} />
                <h2>Gyermekágyas kísérés</h2>
                <p>A gyermekágyi időszak talán az egyik legcsodálatosabb, de ugyanakkor a legnehezebb is. Miben lehetek a segítségetekre? Gyakorlatilag ,amire szükség van. Vigyázni pár órát a babádra, hogy végre beülhess egy kád fürdővízbe, vagy ha épp egy beszélgetésre lenne szükséged,szülésélményed szeretnéd megosztani,átbeszélni,vagy ha szoptatási nehézségekkel küzdesz,abban is tudok segíteni,megmutatom gyakorlatban a babaápolási feladatokat,amiben úgy érzed bizonytalan vagy.  Ez egy teljesen személyre szabott támogatás,a család igényeire szabva. Ha úgy érzed jól jönne az első hónapokban a segítség,keress bizalommal!</p>
                <CTA />
            </div>
            <Footer />
        </div>
    );
}

export default Services;