import Header from "../components/Header";
import Footer from "../components/Footer";
import './About.css';

function About() {
    return (

        <div className="About">
            <Header />
            <div className="about">
                <h1>Ismerj meg</h1>
                <img class='ismerj-meg' alt="főkép" src={require("../static/img/ismerj_meg_3.webp")} />
                <p>Lakatos Anita vagyok Csecsemő-és Gyermekápoló,dúla. Emellett okleveles elsősegély oktató, babamasszázs oktató, önkéntes szoptatási segítő és kismamamasszőr.</p>
                <p>Hivatásomat 2018-ban a veszprémi megyei kórház szülészet-és újszülött osztályán kezdtem,majd 2 és fél  év elteltével egy budapesti magánklinika szülészetén folytattam. Fő feladatom az újszülöttek ellátása,ápolása, a szoptatásban való segítségnyújtás. Segítek a szülőknek elsajátítani a gyakorlati feladatokat,hogy gyermeküket már otthon, a kórházi napok után biztonságban láthassák el. Legfontosabbnak az újszülött és a szülei közötti kapcsolat kialakításában való segítségnyújtást tartom,emellett pedig az édesanya és édesapa szüléssel kapcsolatos élményeinek átbeszélésére,feldolgozására is nagy hangsúlyt fektetek. Munkám során mindennap szembesülök a szülés utáni csodás pillanatokkal, de a nehézségekkel is ,amivel a szülők találkoznak. </p>
                <p>Ezen tapasztalataim után döntöttem úgy,hogy szeretném a szülőket felkészíteni a teljes körű babavárásra. Ezért is végeztem el fő végzettségem mellé, Békés Emőke 2 éves dúlaképzését, melynek első szülés kísérésem egy ukrán menekült édesanya lehetett.</p>
                <p>Fő motivációm és küldetéstudatom,hogy legyen egy olyan kapaszkodó a szülők számára, ahol nincs tabu téma, bármit megkérdezhetnek,fejlődhetnek,tanulhatnak, gyakorlatot és magabiztosságot szerezhetnek gyermekük érkezéséig,kaphatnak egy olyan lelki támaszt ,ami segítheti őket átlendülni a nehéz időszakokon. Ezt a 2022-ben végzett piackutatásom is megerősítette,hogy óriási igény van arra,hogy legyen valaki,akivel meg lehet beszélni a szülőség hozta megpróbáltatásokat. Ezért hoztam létre a KéSzülőt.</p>
                <img class='ismerj-meg' alt="főkép" src={require("../static/img/ismerj_meg.jpeg")} />
                <img class='ismerj-meg' alt="főkép" src={require("../static/img/ismerj_meg_2.webp")} />
            </div>


            <Footer />
        </div >
    );
}
export default About;