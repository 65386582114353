import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Burger.css';

function Burger({ setBurgerState }) {

    const handleArrowClick = () => {
        setBurgerState(false);
    };

    return (
        <div className="Burger">
            <FontAwesomeIcon id='backArrow' size="lg" icon={faArrowLeft} onClick={handleArrowClick} />
            <a href='/about'><h2>Ismerj meg</h2></a>
            <a href='/services'><h2>Szolgáltatások</h2></a>
            <a href='/prices'><h2>Árak</h2></a>
            <a href='/contact'><h2>Időpontfoglalás</h2></a>
            <a href='/gallery'><h2>Galéria</h2></a>
            <a href='/contact'><h2>Elérhetőségek</h2></a>
        </div>
    );
}

export default Burger;