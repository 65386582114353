import './CTA.css';

function CTA() {


    return (
        <div className="CTA">
            <a href='/contact'><h2>JELENTKEZEM</h2></a>
        </div>
    );
}

export default CTA;