import { Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import './App.css';
import About from './pages/About';
import Prices from './pages/Prices';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Admin from './pages/Admin';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<Landing />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/prices' element={<Prices />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/admin' element={<Admin />} />

      </Routes>
    </div>
  );
}

export default App;
