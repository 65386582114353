import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
import Burger from './Burger';
import { useState } from 'react';

function Header() {
  const [burgerState, setBurgerState] = useState(false);

  const handleBurgerClick = () => {
    setBurgerState(true);
  };

  return (
    <div className="Header">
      {burgerState && <Burger setBurgerState={setBurgerState} />}


      {!burgerState && <><div className="burger">
        <img alt="logo" className='main-logo' src={require("../static/img/android-chrome-192x192.png")} />
        <FontAwesomeIcon className='main-burger-logo' size="2xl" icon={faBars} onClick={handleBurgerClick} />

        <a className='main-header' href='/'><h1>kéSzülő</h1></a>
      </div>
      </>

      }

    </div>
  );
}

export default Header;
